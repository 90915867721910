import { useContext, useEffect, useState } from "react";

import {
    MdCheckCircleOutline,
    MdHighlightOff,
    MdOutlineAssignmentReturned,
    MdOutlineContactSupport
} from "react-icons/md";
import InfoContext from "../../context/InfoContext";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import { SolicitationsProps } from "../../types/Solicitations";
import { ActionModal } from "../ActionModal";

import moment from "moment";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import "./styles.css";

type TableProps = {
    list: SolicitationsProps[];
    reload: boolean;
    deny: Function;
    acept: Function;
};

export default function SolicitationTable({
    list,
    deny,
    acept,
    reload,
}: TableProps) {
    const modal = useCustomModal();
    const [itemId, setItemId] = useState<string>("");
    const { reloadData, setReloadData } = useContext(InfoContext);
    const token = localStorage.getItem("BVClient@token");

    useEffect(() => {
        setReloadData(!reloadData);
    }, [reload]);

    async function removeUser(id: string) {
        await api.delete(`solicitations/delete/${id}`);
        // await setIsOpen(false);
        setReloadData(!reloadData);
    }

    async function messageJustificativa(message: string) {
        Swal.fire({
            icon: "info",
            title: "Justificativa",
            text: message,
        });
    }

    async function triggerModalCancel(id: string) {
        Swal.fire({
            title: "Tem certeza disso?",
            text: "deseja cancelar a solicitação? por favor justifique!",
            icon: "warning",
            input: "text",
            showCancelButton: true,
            confirmButtonColor: "#ab0505",
            cancelButtonColor: "#757575",
            confirmButtonText: "Sim",
        }).then((result) => {
            console.log(result);
            if (result.isConfirmed && result.value) {
                cancelSolicitation(id, result.value);
            } else if (result.isConfirmed) {
                Swal.fire({
                    icon: "error",
                    title: "Registro não cancelado",
                    text: "para cancelar é necessário se justificar",
                });
            }
        });
    }

    async function cancelSolicitation(id: string, justificativa: string) {
        const data = { justificativa };
        await api.put(`solicitations/cancel/${id}`, data);
        // await setIsOpen(false);
        setReloadData(!reloadData);
    }

    async function downloadFile(type: string, id: string) {
        try {
            const response = await api.get(
                `solicitations/generate/${type}/${id}`,
                {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${token?.replaceAll(`"`, "")}`,
                    },
                }
            );
            let url = window.URL.createObjectURL(response.data);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${type === "excel" ? "preAuditoria.xlsx" : "auditoria.pdf"
                }`;
            a.click();
        } catch (e) {
            console.log("erro", e);
        }
    }

    return (
        <>
            <body className="flex items-center justify-center">
                <div className="container hidden sm:block">
                    <table className="flex flex-row flex-no-wrap w-full my-5 overflow-hidden rounded-lg sm:bg-white sm:shadow-lg">
                        <thead className="text-white">
                            {list &&
                                list.map((item) => (
                                    <tr className="flex flex-col mb-2 rounded-l-lg bg-mainGray flex-no wrap sm:table-row sm:rounded-none sm:mb-0">
                                        <th className="p-3 text-left">
                                            Pedido
                                        </th>
                                        <th className="p-3 text-left">
                                            Data da inspeção
                                        </th>
                                        <th className="p-3 text-left">
                                            Periodo
                                        </th>
                                        <th className="p-3 text-left">
                                            Artigo
                                        </th>
                                        {/* <th className="p-3 text-left">Categoria</th> */}
                                        {/* <th className="p-3 text-left">Material</th> */}
                                        <th className="p-3 text-left">
                                            Quantidade
                                        </th>
                                        <th className="p-3 text-left">
                                            Status
                                        </th>
                                        <th className="p-3 text-left ">
                                            Ações
                                        </th>
                                    </tr>
                                ))}
                        </thead>
                        <tbody className="flex-1 sm:flex-none">
                            {list &&
                                list.map((item) => (
                                    <tr className="flex flex-col mb-2 bg-white flex-no wrap sm:table-row sm:mb-0">
                                        <td className="p-3 border border-grey-light hover:bg-gray-100">
                                            {item.numberSolicitation
                                                ? item.numberSolicitation
                                                : "Não possui"}
                                        </td>
                                        <td className="p-3 border border-grey-light hover:bg-gray-100">
                                            {moment(item.date_call)
                                                .utc()
                                                .format("DD/MM/YYYY")}
                                        </td>
                                        <td className="p-3 truncate border border-grey-light hover:bg-gray-100">
                                            {item.period}
                                        </td>
                                        <td className="p-3 truncate border border-grey-light hover:bg-gray-100">
                                            {item.article}
                                        </td>
                                        <td className="p-3 truncate border border-grey-light hover:bg-gray-100">
                                            {item.quantity}
                                        </td>
                                        <td className="p-3 truncate border border-grey-light hover:bg-gray-100">
                                            <span
                                                className={`
                                                inline-flex rounded-full
                                                ${item.status == "pendente"
                                                        ? "bg-blue-100"
                                                        : ""
                                                    }
                                                ${item.status == "confirmado"
                                                        ? "bg-blue-100"
                                                        : ""
                                                    }
                                                ${item.status == "cancelado"
                                                        ? "bg-red-100"
                                                        : ""
                                                    }
                                                ${item.status == "reprovado"
                                                        ? item.acceptedOrDenied ===
                                                            undefined
                                                            ? "bg-red-100"
                                                            : item.acceptedOrDenied ===
                                                                true
                                                                ? "bg-green-100"
                                                                : "bg-red-100"
                                                        : ""
                                                    }
                                                ${item.status == "noshow"
                                                        ? "bg-red-100"
                                                        : ""
                                                    }
                                                ${item.status == "aprovado"
                                                        ? "bg-green-100"
                                                        : ""
                                                    }
                                                px-2
                                                text-xs font-semibold leading-5
                                                ${item.status == "pendente"
                                                        ? "text-blue-800"
                                                        : ""
                                                    }
                                                ${item.status == "confirmado"
                                                        ? "text-blue-800"
                                                        : ""
                                                    }
                                                ${item.status == "cancelado"
                                                        ? "text-red-800"
                                                        : ""
                                                    }
                                                ${item.status == "reprovado"
                                                        ? item.acceptedOrDenied ===
                                                            undefined
                                                            ? "text-red-800"
                                                            : item.acceptedOrDenied ===
                                                                true
                                                                ? "text-green-800"
                                                                : "text-red-800"
                                                        : ""
                                                    }
                                                ${item.status == "noshow"
                                                        ? "text-red-800"
                                                        : ""
                                                    }
                                                ${item.status == "aprovado"
                                                        ? "text-green-800"
                                                        : ""
                                                    }

                                            `}
                                            >
                                                {item.status == "noshow" ? (
                                                    <>Now Show</>
                                                ) : (
                                                    <>
                                                        {item.status.replace(
                                                            /^./,
                                                            (str) =>
                                                                str.toUpperCase()
                                                        )}
                                                    </>
                                                )}
                                                {item.acceptedOrDenied ===
                                                    true && " e aceito"}
                                                {item.acceptedOrDenied ===
                                                    false && " e negado"}
                                            </span>
                                        </td>
                                        <td className="p-3 border border-grey-light hover:bg-gray-100 text-mainDarkRed hover:text-red-600 hover:font-medium">
                                            <div className="flex flex-nowrap">
                                                {(item.status === "reprovado" ||
                                                    item.status ===
                                                    "aprovado" ||
                                                    item.status ===
                                                    "noshow") && (
                                                        <div
                                                            onClick={() =>
                                                                toast.promise(
                                                                    downloadFile(
                                                                        "pdf",
                                                                        item.id
                                                                    ),
                                                                    {
                                                                        loading: 'Carregando...',
                                                                        success: 'Arquivo baixado com sucesso!',
                                                                        error: 'Erro ao baixar arquivo!',
                                                                    }
                                                                )

                                                            }
                                                            className={`text-mainGray hover:text-mainDarkRed transition-all duration-500 `}
                                                        >
                                                            <MdOutlineAssignmentReturned size="1.3em" />
                                                        </div>
                                                    )}
                                                {item.status === "reprovado" &&
                                                    item.acceptedOrDenied !==
                                                    false &&
                                                    item.acceptedOrDenied !==
                                                    true && (
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                acept(item.id)
                                                            }
                                                        >
                                                            <div
                                                                className={`text-mainGray hover:text-mainDarkRed transition-all duration-500 `}
                                                            >
                                                                <MdCheckCircleOutline size="1.3em" />
                                                            </div>
                                                        </div>
                                                    )}
                                                {item.status === "reprovado" &&
                                                    item.acceptedOrDenied !==
                                                    false &&
                                                    item.acceptedOrDenied !==
                                                    true && (
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                deny(item.id)
                                                            }
                                                        >
                                                            <div
                                                                className={`text-mainGray hover:text-mainDarkRed transition-all duration-500 `}
                                                            >
                                                                <MdHighlightOff size="1.3em" />
                                                            </div>
                                                        </div>
                                                    )}

                                                {item.status == "cancelado" ? (
                                                    <a
                                                        className="cursor-pointer"
                                                        onClick={() =>
                                                            messageJustificativa(
                                                                item.justificativa
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={`text-mainGray hover:text-mainDarkRed transition-all duration-500 `}
                                                        >
                                                            <MdOutlineContactSupport
                                                                size={"1.3em"}
                                                            />
                                                        </div>
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <ActionModal
                    type={modal.customModal.icon}
                    title={modal.customModal.title}
                    description={modal.customModal.text}
                    isOpen={modal.customModal.status}
                    setIsOpen={modal.handleCustomModalClose}
                    action={removeUser}
                    itemId={itemId}
                />
            </body>
            <div>
                {list &&
                    list.map((item) => (
                        <div className="flex justify-center h-full mt-4 overflow-hidden bg-white border border-gray-200 rounded-xl sm:hidden ">
                            <div>
                                <div className="flex items-center justify-start h-12 px-2 font-semibold text-left text-white border-t bg-mainGray ">
                                    Pedido
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 font-semibold text-left text-white border-t bg-mainGray ">
                                    Data da inspeção
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 font-semibold text-left text-white border-t bg-mainGray ">
                                    Periodo
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 font-semibold text-left text-white border-t bg-mainGray ">
                                    Artigo
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 font-semibold text-left text-white border-t bg-mainGray ">
                                    Quantidade
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 font-semibold text-left text-white border-t bg-mainGray ">
                                    Status
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 font-semibold text-left text-white border-t bg-mainGray ">
                                    Ações
                                </div>
                            </div>
                            <div className="w-full ">
                                <div className="flex items-center justify-start h-12 px-2 border-t border-grey-light">
                                    {item.numberSolicitation
                                        ? item.numberSolicitation
                                        : "Não possui"}
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 border-t border-grey-light">
                                    {moment(item.date_call)
                                        .utc()
                                        .format("DD/MM/YYYY")}
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 border-t border-grey-light">
                                    {item.period}
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 border-t border-grey-light">
                                    {item.article}
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 border-t border-grey-light">
                                    {item.quantity}
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 border-t border-grey-light">
                                    <span
                                        className={`
                                                inline-flex rounded-full
                                                ${item.status == "pendente"
                                                ? "bg-blue-100"
                                                : ""
                                            }
                                                ${item.status == "confirmado"
                                                ? "bg-blue-100"
                                                : ""
                                            }
                                                ${item.status == "cancelado"
                                                ? "bg-red-100"
                                                : ""
                                            }
                                                ${item.status == "reprovado"
                                                ? item.acceptedOrDenied ===
                                                    undefined
                                                    ? "bg-red-100"
                                                    : item.acceptedOrDenied ===
                                                        true
                                                        ? "bg-green-100"
                                                        : "bg-red-100"
                                                : ""
                                            }
                                                ${item.status == "noshow"
                                                ? "bg-red-100"
                                                : ""
                                            }
                                                ${item.status == "aprovado"
                                                ? "bg-green-100"
                                                : ""
                                            }
                                                px-2
                                                text-xs font-semibold leading-5
                                                ${item.status == "pendente"
                                                ? "text-blue-800"
                                                : ""
                                            }
                                                ${item.status == "confirmado"
                                                ? "text-blue-800"
                                                : ""
                                            }
                                                ${item.status == "cancelado"
                                                ? "text-red-800"
                                                : ""
                                            }
                                                ${item.status == "reprovado"
                                                ? item.acceptedOrDenied ===
                                                    undefined
                                                    ? "text-red-800"
                                                    : item.acceptedOrDenied ===
                                                        true
                                                        ? "text-green-800"
                                                        : "text-red-800"
                                                : ""
                                            }
                                                ${item.status == "noshow"
                                                ? "text-red-800"
                                                : ""
                                            }
                                                ${item.status == "aprovado"
                                                ? "text-green-800"
                                                : ""
                                            }

                                            `}
                                    >
                                        {item.status == "noshow" ? (
                                            <>Now Show</>
                                        ) : (
                                            <>
                                                {item.status.replace(
                                                    /^./,
                                                    (str) => str.toUpperCase()
                                                )}
                                            </>
                                        )}
                                        {item.acceptedOrDenied === true &&
                                            " e aceito"}
                                        {item.acceptedOrDenied === false &&
                                            " e negado"}
                                    </span>
                                </div>
                                <div className="flex items-center justify-start h-12 px-2 transition-all duration-500 border-t cursor-pointer border-grey-light hover:bg-gray-100 text-mainDarkRed">
                                    <div className="flex flex-nowrap">
                                        {(item.status === "reprovado" ||
                                            item.status === "aprovado" ||
                                            item.status === "noshow") && (
                                                <div
                                                    onClick={() =>
                                                        toast.promise(
                                                            downloadFile("pdf", item.id),
                                                            {
                                                                loading: 'Carregando...',
                                                                success: 'Arquivo baixado com sucesso!',
                                                                error: 'Erro ao baixar arquivo!',
                                                            }
                                                        )

                                                    }
                                                    className={`text-mainGray hover:text-mainDarkRed transition-all duration-500 `}
                                                >
                                                    <MdOutlineAssignmentReturned size="1.3em" />
                                                </div>
                                            )}
                                        {item.status === "reprovado" &&
                                            item.acceptedOrDenied !== false &&
                                            item.acceptedOrDenied !== true && (
                                                <div
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        acept(item.id)
                                                    }
                                                >
                                                    <div
                                                        className={`text-mainGray hover:text-mainDarkRed transition-all duration-500 `}
                                                    >
                                                        <MdCheckCircleOutline size="1.3em" />
                                                    </div>
                                                </div>
                                            )}
                                        {item.status === "reprovado" &&
                                            item.acceptedOrDenied !== false &&
                                            item.acceptedOrDenied !== true && (
                                                <div
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        deny(item.id)
                                                    }
                                                >
                                                    <div
                                                        className={`text-mainGray hover:text-mainDarkRed transition-all duration-500 `}
                                                    >
                                                        <MdHighlightOff size="1.3em" />
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                <ActionModal
                    type={modal.customModal.icon}
                    title={modal.customModal.title}
                    description={modal.customModal.text}
                    isOpen={modal.customModal.status}
                    setIsOpen={modal.handleCustomModalClose}
                    action={removeUser}
                    itemId={itemId}
                />
            </div>
        </>
    );
}
