import { useContext } from "react";
import { Switch } from "react-router-dom";

import Dashboard from "../../pages/Dashboard";

import FactorySolicitationForm from "../../pages/FactorySolicitationForm";
import AdminRoute from "../AdminRoute";
import FactorySolicitation from "../../pages/FactorySolicitation";
import FactorySolicitationEdit from "../../pages/FactorySolicitationEdit";
import PreAuditoriaAnswer from "../../pages/PreAuditoriaAnswer";

const AuthenticatedRoute = () => (
    <Switch>
        <AdminRoute exact path="/" component={() => <Dashboard />} />

        <AdminRoute
            path="/solicitacao"
            component={() => <FactorySolicitation />}
        />
        <AdminRoute
            path="/solicitacao-formulario"
            component={() => <FactorySolicitationForm />}
        />

        <AdminRoute
            exact
            path="/solicitacaoeditar/:id"
            component={() => <FactorySolicitationEdit />}
        />

        <AdminRoute
            exact
            path="/resultadoauditoria/:id"
            component={() => <PreAuditoriaAnswer />}
        />
    </Switch>
);

export default AuthenticatedRoute;
