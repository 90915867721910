import { createContext, useEffect, useState } from "react";
import { AlertModal } from "../components/AlertModal";
// import { AlertModal } from "../components/AlertModal";
import { useCustomModal } from "../hooks/useCustomModal";
import api from "../services/api";

import history from "../services/history";
import { UserProps } from "../types/User";

type LoginData = {
    email: string;
    password: string;
};

type AuthProps = {
    authenticated: boolean;
    handleLogin: (data: LoginData) => Promise<void>;
    loading: boolean;
    handleLogOut: () => void;
    user: UserProps | null;
    isActiveLogin: boolean;
    setIsActiveLogin: (state: boolean) => void;
};

const DEFAULT_VALUE = {
    authenticated: false,
    handleLogin: async () => {},
    loading: true,
    handleLogOut: () => {},
    user: null,
    isActiveLogin: false,
    setIsActiveLogin: () => {},
};

const Context = createContext<AuthProps>(DEFAULT_VALUE);

// removido react FC verificar
const AuthProvider = ({ children }: any) => {
    const [authenticated, setAuthenticated] = useState(
        DEFAULT_VALUE.authenticated
    );
    const [loading, setLoading] = useState(DEFAULT_VALUE.loading);
    const [user, setUser] = useState(DEFAULT_VALUE.user);
    const [isActiveLogin, setIsActiveLogin] = useState(
        DEFAULT_VALUE.isActiveLogin
    );

    const modal = useCustomModal();

    useEffect(() => {
        const token = localStorage.getItem("BVClient@token");
        const user = localStorage.getItem("BVClient@user");

        // colocar dentro do else
        // setLoading(false);

        if (token && user) {
            api.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
                token
            )}`;

            setUser(JSON.parse(user));
            setAuthenticated(true);
            setLoading(false);
        } else {
            localStorage.removeItem("BVClient@token");
            localStorage.removeItem("BVClient@user");
        }
    }, []);

    async function handleLogin({ email, password }: LoginData) {
        try {
            const dataLogin = {
                email: email,
                password: password,
            };
            const {
                data: { token, client },
            } = await api
                .post("/sessionsClient/loginClient", dataLogin)
                .catch((err) => {
                    modal.setCustomModal({
                        status: true,
                        icon: "error",
                        title: "Falha ao acessar!",
                        text: err.response.data.message,
                        cancelButton: "",
                        confirmButton: "",
                    });
                    throw err.response.status;
                });
            localStorage.setItem("BVClient@token", JSON.stringify(token));
            localStorage.setItem("BVClient@user", JSON.stringify(client));
            api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            history.push("/");
            setUser(client);
            setAuthenticated(true);
        } catch {}
    }

    function handleLogOut() {
        localStorage.removeItem("BVClient@token");
        localStorage.removeItem("BVClient@user");

        api.defaults.headers.common["Authorization"] = "";

        // localStorage.removeItem("ESSENCIAL@terms");
        // // window.location.replace("/");
        setAuthenticated(false);
        history.push("/"); //verify
    }

    // verify loading

    // if (loading) {
    //     return <h1>Loading...</h1>;
    // }

    return (
        <Context.Provider
            value={{
                authenticated,
                handleLogin,
                loading,
                handleLogOut,
                user,
                isActiveLogin,
                setIsActiveLogin,
            }}
        >
            {children}

            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
            />
        </Context.Provider>
    );
};

export { Context, AuthProvider };
