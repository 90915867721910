import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";
import { Context } from "../../context/AuthContext";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { handleChangeMask } from "../../services/utils/mask";
import { transformDate } from "../../services/utils/convertDate";
import moment from 'moment';
import { addressByCep } from "../../services/utils/getAddressByCep";

interface SolicitationProps {
    date_call: string;
    period: string;
    article: string;
    has_table_measurement: string;
    table_code: string;
    article_classification: string;
    category: string;
    material: string;
    quantity: number;
    level_of_pricing: string;
    responsible: string;
    factory_id: string;
    cargo: string;
    product: number;
    clothingSet: string;
    level_avaliation: string;

    tipo_produto: string;
    numero_pedido: string;

    zip_code: string;
    address: string;
    number: string;
    district: string;
    description: string;
    city: string;
    state: string;
    address_id: string;



    // address: {
    //     zip_code: string;
    //     address: string;
    //     number: string;
    //     district: string;
    //     description: string;
    //     city: string;
    //     state: string;
    // };
    products: [];
}

interface ProductAndClothingSelect {
    id: number;
    name: string;
}

const schema = yup.object().shape({

    // cargo: yup.string().required("Selecione um cargo"),

    numero_pedido: yup.string().required("Campo é obrigatório"),
    date_call: yup.string().required("Campo é obrigatório"),
    period: yup.string().required("Campo é obrigatório"),
    article: yup.string().required("Campo é obrigatório"),

    // table_code: yup.string().when("has_table_measurement", {
    //     is: (hasTable: any) => hasTable === 'sim',
    //     then: yup.string().required('Campo obrigatório').nullable()
    // }),

    clothingSet: yup.string().when('tipo_produto', {
        is: (tipoProduto: any) => tipoProduto == 'conjunto',
        then: yup.string().required('Por favor escolha o conjunto')
    }),

    product: yup.string().when('tipo_produto', {
        is: (tipoProduto: any) => tipoProduto == 'produto',
        then: yup.string().required('Por favor escolha o produto')
    }),

    quantity: yup.string().required("Campo é obrigatório"),
    level_of_pricing: yup.string().required("Campo é obrigatório"),
    responsible: yup.string().required("Campo é obrigatório"),
    cargo: yup.string().required("Campo é obrigatório"),
    level_avaliation: yup.string().required("O regime de inspeção é obrigatória"),



    number: yup
        .string()
        .required("Campo obrigatório")
        .max(10, "Máximo 10 caracteres"),
    address: yup
        .string()
        .required("Campo obrigatório"),
    district: yup
        .string()
        .required("Campo obrigatório")
        .max(150, "Máximo 150 caracteres"),
    description: yup
        .string()
        .required("Campo obrigatório")
        .max(150, "Máximo 150 caracteres"),
    city: yup
        .string()
        .required("Campo obrigatório")
        .max(150, "Máximo 150 caracteres"),
    state: yup
        .string()
        .required("Campo obrigatório")
        .max(2, "Máximo 2 caracteres"),
    zip_code: yup
        .string()
        .required("Campo obrigatório")
        .min(9, "Mínimo 9 caracteres"),
    // factory_id: "269b7eb3-d056-42ad-a3eb-31687658f41c",
});

export default function FactorySolicitationEdit() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [period, setPeriod] = useState<string>("Manhã");
    const [table, setTable] = useState<string>("Não");
    const [articleClassification, setArticleClassification] =
        useState<string>("Não");

    const { handleLogOut, user } = useContext(Context);

    const [possuiMedidas, setPossuiMedidas] = useState<boolean>(false);
    const [isClothingSet, setIsClothingSet] = useState<boolean>(false);
    const [date, setDate] = useState<any>();

    const [listSelectProduct, setListSelectProduct] = useState<ProductAndClothingSelect[]>();
    const [listSelectClothingSet, setListSelectClothingSet] = useState<ProductAndClothingSelect[]>();

    const [cep, setCep] = useState<string>("");

    const modal = useCustomModal();

    let { id }: { id: string } = useParams();

    const getSolicitation = useCallback(async () => {
        const productSelect = await api.get(`products/list/select`);
        setListSelectProduct(productSelect.data);

        const clothingSetSelect = await api.get(`clothingsets/list/select`);
        setListSelectClothingSet(clothingSetSelect.data);

        if (id) {
            const { data } = await api.get(`solicitations/${id}`);

            console.log(data);
            setValue('date_call', moment(data.date_call).utc().format('YYYY-MM-DD'));
            setDate(moment(data.date_call).utc().format('YYYY-MM-DD'));

            setValue('numero_pedido', data.numero_pedido);
            setValue('period', data.period);
            setValue('article', data.article);
            if (data.has_table_measurement) {
                setValue('has_table_measurement', 'sim');
                setPossuiMedidas(true);
            } else {
                setValue('has_table_measurement', 'nao');
                setPossuiMedidas(false);
            }

            setValue('table_code', data.table_code);
            setValue('article_classification', data.article_classification);
            setValue('category', data.category);
            setValue('quantity', data.quantity);
            setValue('level_of_pricing', data.level_of_pricing);
            setValue('level_avaliation', data.level_avaliation);
            setValue('responsible', data.responsible);
            setValue('cargo', data.cargo);
            setValue('address_id', data.address_id)

            if (data.clothing_set_id) {

                setValue(
                    "tipo_produto",
                    'conjunto'
                );
                setIsClothingSet(true);
                setValue(
                    "clothingSet",
                    data.clothing_set_id
                );
            } else {
                setValue(
                    "tipo_produto",
                    'produto'
                );
                setIsClothingSet(false);
                setValue(
                    "product",
                    data.solicitationHasProducts.length ? data.solicitationHasProducts[0].product_id : ''
                );

            }


            setValue('zip_code', data.address.zip_code);
            setValue('address', data.address.address);
            setValue('number', data.address.number);
            setValue('district', data.address.district);
            setValue('description', data.address.description);
            setValue('city', data.address.city);
            setValue('state', data.address.state);
        }
    }, [id]);

    useEffect(() => {

        getSolicitation();
    }, []);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<SolicitationProps>({
        resolver: yupResolver(schema),
    });

    async function handleRegisterClient(data: SolicitationProps) {
        setIsLoading(true);

        console.log(data)
        const dataRegistration = {
            date_call: data.date_call,
            period: data.period,
            article: data.article,
            has_table_measurement: possuiMedidas,
            table_code: data.table_code,
            article_classification: data.article_classification,
            category: data.category,
            material: data.material,
            quantity: data.quantity,
            level_of_pricing: data.level_of_pricing,
            level_avaliation: data.level_avaliation,
            responsible: data.responsible,
            cargo: data.cargo,
            address_id: data.address_id,
            numero_pedido: data.numero_pedido,
            address: {
                zip_code: data.zip_code,
                address: data.address,
                number: data.number,
                district: data.district,
                description: data.description,
                city: data.city,
                state: data.state,
            },
            products: [] as any,
            clothing_set_id: null as any
        };

        if (isClothingSet) {
            dataRegistration.clothing_set_id = data.clothingSet;
        } else {
            dataRegistration.products = [{ id: data.product }]
        }

        try {

            const response = await api.put(
                `solicitations/update/${id}`,
                dataRegistration
            );
            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Solicitação confirmado com sucesso!",
                    text: "Agora você pode acessá-lo na área de solicitações",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: "Não foi possível realizar a confirmação",
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }

    }

    async function completeAddress(cep: string) {

        if (cep.length == 9) {
            let cepSearch = cep.replace('-', '');
            const address = await addressByCep(cepSearch);

            // setValue('zip_code', address.cep);
            if (address.logradouro) {
                setValue('address', address.logradouro);
                setValue('number', address.number);
                setValue('district', address.bairro);
                setValue('description', address.complemento);
                setValue('city', address.localidade);
                setValue('state', address.uf);
            }

        }
    }


    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Editar solicitação
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área para edição de solicitação
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="userRegisterForm"
                            onSubmit={handleSubmit(handleRegisterClient)}
                        >
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nº do Pedido
                                    </label>
                                    <input
                                        {...register("numero_pedido")}
                                        type="number"
                                        name="numero_pedido"
                                        id="numero_pedido"
                                        min="0"
                                        autoComplete="numero_pedido"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "numero_pedido",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.numero_pedido && (
                                        <span className="text-mainDarkRed">
                                            {errors.numero_pedido.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="date_call"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Data de agendamento
                                    </label>
                                    <input
                                        {...register("date_call")}
                                        type="date"
                                        name="date_call"
                                        id="date_call"
                                        min={new Date().toISOString().split('T')[0]}
                                        autoComplete="given-date"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "date_call",
                                                e.target.value
                                            );
                                            setDate(e.target.value);
                                        }}
                                        value={date}
                                    />
                                    {errors.date_call && (
                                        <span className="text-mainDarkRed">
                                            {errors.date_call.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="period"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Período
                                    </label>
                                    <select
                                        {...register("period")}
                                        id="period"
                                        name="period"
                                        autoComplete="period-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "period",
                                                e.target.value
                                            );
                                        }}
                                    // onChange={(e) =>
                                    //     setPeriod(e.target.value)
                                    // }
                                    >
                                        <option>Manhã</option>
                                        <option>Tarde</option>
                                        <option>Integral</option>
                                    </select>
                                    {errors.period && (
                                        <span className="text-mainDarkRed">
                                            {errors.period.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="article"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Artigo
                                    </label>
                                    <input
                                        {...register("article")}
                                        type="text"
                                        name="article"
                                        id="article"
                                        autoComplete="article"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "article",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.article && (
                                        <span className="text-mainDarkRed">
                                            {errors.article.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="period"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Possui tabela de medidas?
                                    </label>
                                    <select
                                        {...register("has_table_measurement")}
                                        id="has_table_measurement"
                                        name="has_table_measurement"
                                        autoComplete="has_table_measurement-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            if (e.target.value == 'sim') {
                                                setPossuiMedidas(true);
                                                // setValue('has_table_measurement', 'sim');
                                            } else {
                                                setPossuiMedidas(false);
                                                // setValue('has_table_measurement', 'nao');
                                            }
                                            // setTable(e.target.value)
                                        }

                                        }
                                    >
                                        <option value={'nao'}>Não</option>
                                        <option value={'sim'}>Sim</option>
                                    </select>
                                    {errors.period && (
                                        <span className="text-mainDarkRed">
                                            {errors.period.message}
                                        </span>
                                    )}
                                </div>

                                {
                                    possuiMedidas ?
                                        <>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="table_code"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Código da tabela
                                                </label>
                                                <input
                                                    {...register("table_code")}
                                                    type="text"
                                                    name="table_code"
                                                    id="table_code"
                                                    autoComplete="table_code"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                    onChange={(e) => {

                                                        setValue(
                                                            "table_code",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                {errors.table_code && (
                                                    <span className="text-mainDarkRed">
                                                        {errors.table_code.message}
                                                    </span>
                                                )}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="article_classification"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Classificação do Artigo
                                                </label>
                                                <select
                                                    {...register("article_classification")}
                                                    id="article_classification"
                                                    name="article_classification"
                                                    autoComplete="article_classification-name"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                    onChange={(e) => {

                                                        setValue(
                                                            "article_classification",
                                                            e.target.value
                                                        );
                                                    }}

                                                // onChange={(e) =>
                                                //     setArticleClassification(
                                                //         e.target.value
                                                //     )
                                                // }
                                                >
                                                    <option>Feminino</option>
                                                    <option>Masculino</option>
                                                    <option>Infantil</option>
                                                </select>
                                                {errors.period && (
                                                    <span className="text-mainDarkRed">
                                                        {errors.period.message}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="category"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Categoria do Produto
                                                </label>
                                                <select
                                                    {...register("category")}
                                                    id="category"
                                                    name="category"
                                                    autoComplete="category-name"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                    onChange={(e) => {

                                                        setValue(
                                                            "category",
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option>Top</option>
                                                    <option>Botton</option>
                                                    <option>Inteiro</option>
                                                    <option>Acessório</option>
                                                    <option>Esportivo</option>
                                                    <option>Intima</option>
                                                </select>
                                                {errors.category && (
                                                    <span className="text-mainDarkRed">
                                                        {errors.category.message}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="material"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Material
                                                </label>
                                                <select
                                                    {...register("material")}
                                                    id="material"
                                                    name="material"
                                                    autoComplete="material-name"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                    onChange={(e) => {

                                                        setValue(
                                                            "material",
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option>Malha</option>
                                                    <option>Plano</option>
                                                </select>
                                                {errors.material && (
                                                    <span className="text-mainDarkRed">
                                                        {errors.material.message}
                                                    </span>
                                                )}
                                            </div>
                                        </>

                                }


                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="quantity"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Quantidade
                                    </label>
                                    <input
                                        {...register("quantity")}
                                        type="text"
                                        name="quantity"
                                        id="quantity"
                                        autoComplete="quantity"
                                        min="0"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "quantity",
                                                Number(e.target.value)
                                            );
                                        }}
                                    />
                                    {errors.quantity && (
                                        <span className="text-mainDarkRed">
                                            {errors.quantity.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="level_of_pricing"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nível de Precificação
                                    </label>
                                    <select
                                        {...register("level_of_pricing")}
                                        id="level_of_pricing"
                                        name="level_of_pricing"
                                        autoComplete="level_of_pricing-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "level_of_pricing",
                                                e.target.value
                                            );
                                        }}
                                    >
                                        <option value={`P1`}>P1</option>
                                        <option value={`P2`}>P2</option>
                                        <option value={`P3`}>P3</option>
                                        <option value={`P4`}>P4</option>
                                        <option value={`P5`}>P5</option>
                                    </select>
                                    {errors.level_of_pricing && (
                                        <span className="text-mainDarkRed">
                                            {errors.level_of_pricing.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="level_of_pricing"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Regime de inspeção
                                    </label>
                                    <select
                                        {...register("level_avaliation")}
                                        id="level_avaliation"
                                        name="level_avaliation"
                                        autoComplete="level_avaliation-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "level_avaliation",
                                                e.target.value
                                            );
                                        }}
                                    >
                                        <option value={'intermediario_atenuado'}>Intermediário e Atenuado</option>
                                        <option value={'normal'}>Normal</option>
                                        <option value={'severo'}>Severo</option>
                                    </select>
                                    {errors.level_avaliation && (
                                        <span className="text-mainDarkRed">
                                            {errors.level_avaliation.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="responsible"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Responsável
                                    </label>
                                    <input
                                        {...register("responsible")}
                                        type="text"
                                        name="responsible"
                                        id="responsible"
                                        autoComplete="responsible"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "responsible",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.responsible && (
                                        <span className="text-mainDarkRed">
                                            {errors.responsible.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="cargo"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Cargo
                                    </label>
                                    <input
                                        {...register("cargo")}
                                        type="text"
                                        name="cargo"
                                        id="cargo"
                                        autoComplete="cargo"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "cargo",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.cargo && (
                                        <span className="text-mainDarkRed">
                                            {errors.cargo.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="level_of_pricing"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo do Produto
                                    </label>
                                    <select
                                        {...register("tipo_produto")}
                                        id="tipo_produto"
                                        name="tipo_produto"
                                        autoComplete="tipo_produto-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"

                                        onChange={(e) => {

                                            if (e.target.value == 'conjunto') {
                                                setIsClothingSet(true);
                                                setValue(
                                                    "tipo_produto",
                                                    'conjunto'
                                                );
                                            } else {
                                                setIsClothingSet(false)
                                                setValue(
                                                    "tipo_produto",
                                                    'produto'
                                                );
                                            }
                                        }

                                        }
                                    >
                                        <option value={`produto`}>Produto</option>
                                        <option value={`conjunto`}>Conjunto/Kit</option>
                                    </select>

                                </div>

                                {
                                    isClothingSet ?
                                        <>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="level_of_pricing"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Conjunto/Kit
                                                </label>
                                                <select
                                                    {...register("clothingSet")}
                                                    id="clothingSet"
                                                    name="clothingSet"
                                                    autoComplete="clothingSet"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                    onChange={(e) => {
                                                        setValue(
                                                            "clothingSet",
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={''} >Selecione</option>
                                                    {
                                                        listSelectClothingSet ?
                                                            listSelectClothingSet.map(clothing => (<option key={clothing.id} value={clothing.id} >{clothing.name}</option>))
                                                            :
                                                            ''
                                                    }

                                                </select>
                                                {errors.clothingSet && (
                                                    <span className="text-mainDarkRed">
                                                        {errors.clothingSet.message}
                                                    </span>
                                                )}
                                            </div>
                                        </>
                                        :
                                        <>
                                            {/* <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="level_of_pricing"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Produtos
                                                </label>
                                                <select
                                                    {...register("product")}
                                                    id="product"
                                                    name="product"
                                                    autoComplete="product"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                    onChange={(e) => {
                                                        setValue(
                                                            "product",
                                                            Number(e.target.value)
                                                        );
                                                    }}
                                                >
                                                    <option value={''} >Selecione</option>
                                                    {
                                                        listSelectProduct ?
                                                            listSelectProduct.map(product => (
                                                                <option key={product.id} value={product.id} >{product.name}</option>
                                                            )
                                                            )
                                                            :
                                                            ''
                                                    }

                                                </select>
                                                {errors.product && (
                                                    <span className="text-mainDarkRed">
                                                        {errors.product.message}
                                                    </span>
                                                )}
                                            </div> */}
                                        </>

                                }

                                {/* Parte de Endereços */}

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label
                                        htmlFor="zip_code"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        CEP
                                    </label>
                                    <input
                                        {...register("zip_code")}
                                        type="text"
                                        name="zip_code"
                                        id="zip_code"
                                        autoComplete="zip_code"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            const formated =
                                                handleChangeMask(
                                                    e.target.value,
                                                    "cep"
                                                );
                                            setCep(formated);
                                            setValue(
                                                "zip_code",
                                                e.target.value
                                            );
                                            e.target.value = formated;
                                            completeAddress(formated);
                                        }}
                                    />
                                    {errors.zip_code && (
                                        <span className="text-mainDarkRed">
                                            {errors.zip_code.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-6 lg:col-span-4">
                                    <label
                                        htmlFor="address"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Endereço
                                    </label>
                                    <input
                                        {...register("address")}
                                        type="text"
                                        name="address"
                                        id="address"
                                        autoComplete="address"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "address",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.address && (
                                        <span className="text-mainDarkRed">
                                            {errors.address.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <label
                                        htmlFor="number"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Número
                                    </label>
                                    <input
                                        {...register("number")}
                                        type="text"
                                        name="number"
                                        id="number"
                                        autoComplete="number"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "number",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.number && (
                                        <span className="text-mainDarkRed">
                                            {errors.number.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="district"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Bairro
                                    </label>
                                    <input
                                        {...register("district")}
                                        type="text"
                                        name="district"
                                        id="district"
                                        autoComplete="district"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "district",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.district && (
                                        <span className="text-mainDarkRed">
                                            {errors.district.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="description"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Ponto de referência
                                    </label>
                                    <input
                                        {...register("description")}
                                        type="text"
                                        name="description"
                                        id="description"
                                        autoComplete="description"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "description",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.description && (
                                        <span className="text-mainDarkRed">
                                            {errors.description.message}
                                        </span>
                                    )}
                                </div>


                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <label
                                        htmlFor="city"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Cidade
                                    </label>
                                    <input
                                        {...register("city")}
                                        type="text"
                                        name="city"
                                        id="city"
                                        autoComplete="address-level2"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {

                                            setValue(
                                                "city",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.city && (
                                        <span className="text-mainDarkRed">
                                            {errors.city.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label
                                        htmlFor="state"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Estado
                                    </label>
                                    <select
                                        {...register("state")}
                                        id="state"
                                        name="state"
                                        autoComplete="state-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "state",
                                                e.target.value
                                            );
                                        }}
                                    >
                                        <option value={`AC`}>AC</option>
                                        <option value={`AL`}>AL</option>
                                        <option value={`AP`}>AP</option>
                                        <option value={`AM`}>AM</option>
                                        <option value={`BA`}>BA</option>
                                        <option value={`CE`}>CE</option>
                                        <option value={`DF`}>DF</option>
                                        <option value={`ES`}>ES</option>
                                        <option value={`GO`}>GO</option>
                                        <option value={`MA`}>MA</option>
                                        <option value={`MT`}>MT</option>
                                        <option value={`MS`}>MS</option>
                                        <option value={`MG`}>MG</option>
                                        <option value={`PA`}>PA</option>
                                        <option value={`PB`}>PB</option>
                                        <option value={`PR`}>PR</option>
                                        <option value={`PE`}>PE</option>
                                        <option value={`PI`}>PI</option>
                                        <option value={`RJ`}>RJ</option>
                                        <option value={`RN`}>RN</option>
                                        <option value={`RS`}>RS</option>
                                        <option value={`RO`}>RO</option>
                                        <option value={`RR`}>RR</option>
                                        <option value={`SC`}>SC</option>
                                        <option value={`SP`}>SP</option>
                                        <option value={`SE`}>SE</option>
                                        <option value={`TO`}>TO</option>
                                    </select>
                                    {errors.state && (
                                        <span className="text-mainDarkRed">
                                            {errors.state.message}
                                        </span>
                                    )}

                                </div>



                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Confirmar"
                            form="userRegisterForm"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div >
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/"
            />
        </>
    );
}
