import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import InfoContext from "../../context/InfoContext";
import SolicitationTable from "../../components/Table/SolicitationTable";
import { Pagination } from "../../components/Pagination";
import { AiOutlineClose } from "react-icons/ai";

export default function Dashboard() {
    const [solicitationList, setSolicitations] = useState<any[]>([]);

    const [open, setOpen] = useState(false);
    const [reject, setReject] = useState(false);
    const [selected, setSelected] = useState("");
    const { handleLogOut, user } = useContext(Context);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [reload, setReaload] = useState<boolean>(false);

    const [searchDateStart, setSearchDateStart] = useState<any>("");
    const [searchDateEnd, setSearchDateEnd] = useState<any>("");
    console.log(
        "date",
        searchDateStart && searchDateEnd !== ""
            ? moment(searchDateStart).format("DD/MM/YYYY") +
                  "-" +
                  moment(searchDateEnd).format("DD/MM/YYYY")
            : ""
    );

    // ? search the solicitations list
    const getSolicitations = useCallback(async () => {
        setSolicitations([]);
        try {
            const { data } = await api.get(
                `solicitations?page=${page}&client=${user?.id}&days=${
                    searchDateStart && searchDateEnd !== ""
                        ? moment(searchDateStart).format("DD/MM/YYYY") +
                          "-" +
                          moment(searchDateEnd).format("DD/MM/YYYY")
                        : ""
                }`
            );
            console.log("pesquisou");
            setPage(data.currentPage);
            setLastPage(data.lastPage);
            setSolicitations(data.list);
        } catch {
            // handleLogOut();
        }
    }, [page, user, searchDateStart, searchDateEnd]);

    useEffect(() => {
        getSolicitations();
    }, [reload, page, searchDateStart, searchDateEnd]);

    async function openDeny(id: string) {
        setSelected(id);
        setReject(true);
        setOpen(true);
    }

    async function denyOffer(id: string) {
        // todo requisição de negação
        console.log("deny");
        try {
            await api.put(`solicitations/denied/${id}`);
            console.log("oi");
            setReaload(!reload);
        } catch {
            console.log("não rolou");
        }
        setOpen(false);
    }

    async function openAcept(id: string) {
        setSelected(id);
        setReject(false);
        setOpen(true);
    }

    async function aceptOffer(id: string) {
        // todo requisição de aceitação
        try {
            await api.put(`solicitations/accept/${id}`);
            console.log("oi");
            setReaload(!reload);
        } catch {
            console.log("não rolou");
        }
        setOpen(false);
    }

    useEffect(() => {
        console.log("data->", searchDateStart);
        console.log("modelo ->", new Date().toISOString().split("T")[0]);
    }, [searchDateStart]);

    return (
        <>
            {open && (
                <div className="w-full h-screen fixed -translate-x-4 sm:-translate-x-16  -translate-y-12 flex justify-center items-center backdrop-blur">
                    <div className="w-10/12 sm:w-1/4 sm:-translate-x-36 rounded-lg overflow-hidden shadow-lg shadow-black/20 border border-black/30">
                        <div className="bg-mainGray px-4 py-2 flex justify-end">
                            <div
                                className="text-black/50 hover:text-mainDarkRed cursor-pointer transition-all duration-500"
                                onClick={() => setOpen(false)}
                            >
                                <AiOutlineClose size="1.3em" />
                            </div>
                        </div>
                        <div className="bg-white px-4 py-2">
                            <div className="mb-2">
                                {reject
                                    ? "Deseja recusar esse pedido que foi reprovado na auditoria?"
                                    : "Deseja aceitar esse pedido mesmo após a sua reprovação na auditoria?"}
                            </div>
                            <div className="w-full flex justify-between items-center">
                                <div
                                    onClick={() => setOpen(false)}
                                    className="rounded px-2 h-8 bg-mainDarkRed text-white flex justify-center items-center cursor-pointer hover:bg-mainGray transition-all duration-500"
                                >
                                    Cancelar
                                </div>
                                <div
                                    onClick={() =>
                                        reject
                                            ? denyOffer(selected)
                                            : aceptOffer(selected)
                                    }
                                    className="rounded px-2 h-8 bg-mainGray flex text-white justify-center items-center cursor-pointer hover:bg-mainDarkRed transition-all duration-500"
                                >
                                    Confirmar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <div className="flex justify-between items-center">
                    <h1 className="font-bold text-2xl">
                        Solicitações de inspeção
                    </h1>
                </div>
                <div className="col-span-6 sm:col-span-3 mt-4">
                    <label
                        htmlFor="date_call"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Pesquise por data
                    </label>
                    <div className="flex gap-3 items-center">
                        <input
                            type="date"
                            name="date_call"
                            id="date_call"
                            autoComplete="given-date"
                            onChange={(e) => setSearchDateStart(e.target.value)}
                            className="mt-1 block w-36 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                        />
                        <div className="">até</div>
                        <input
                            type="date"
                            name="date_call"
                            id="date_call"
                            min={searchDateStart}
                            autoComplete="given-date"
                            onChange={(e) => setSearchDateEnd(e.target.value)}
                            className="mt-1 block w-36 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                        />
                    </div>
                </div>
                {solicitationList.length ? (
                    <>
                        <SolicitationTable
                            deny={openDeny}
                            acept={openAcept}
                            reload={reload}
                            list={solicitationList}
                        />
                        <Pagination
                            initPage={1}
                            totalPage={lastPage}
                            page={page}
                            setPage={setPage}
                            path={`products`}
                        />
                    </>
                ) : (
                    <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="py-16">
                            <div className="text-center">
                                <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
                                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                    No momento não temos inspeções agendadas
                                </h1>

                                <p className="mt-2 text-base text-gray-500"></p>
                            </div>
                        </div>
                    </main>
                )}
            </div>
        </>
    );
}
